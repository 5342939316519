Vue.component('hotspots', {
  data() {
    return {
      activeHotspot: null,
    };
  },
  methods: {
    toggleActiveHotspot(hotspotIndex) {
      if (hotspotIndex === null) {
        this.activeHotspot = null;
      } else if (this.activeHotspot !== hotspotIndex) {
        this.activeHotspot = hotspotIndex;
      } else {
        this.activeHotspot = null;
      }
    },
  },
});
